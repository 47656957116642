module.exports = [
  {
    path: '/currency-rate',
    name: 'currency-rate',
    meta: {
      requiresAuth: true,
      permission: 'currency_rates',
      title: 'Tasa de cambio',
      module: 'currency-rate',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-hand-coin',
      isBaseLayout: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "currency-rate" */ '@/views/currency-rate/CurrencyRateLayout.vue'
      ),
    children: [
      {
        path: 'usd-ves',
        name: 'currency-rate-usd-ves',
        meta: {
          requiresAuth: true,
          permission: 'currency_rates',
          title: 'Tasa de cambio - USD',
          module: 'currency-rate',
          layout: 'admin',
          adminLayoutNav: true,
          isBaseLayout: true,
          icon: 'mdi-cash-100'
        },
        component: () =>
          import(
            /* webpackChunkName: "currency-rate-usd-ves" */ '@/views/currency-rate/CurrencyRateUSDVESList.vue'
          )
      },
      {
        path: 'usd-cop',
        name: 'currency-rate-usd-cop',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          layout: 'admin',
          title: 'Tasa de cambio -COP',
          module: 'currency-rate',
          icon: 'mdi-cash-100',
          requiresAuth: true,
          permission: 'currency_rates_cop',
        },
        component: () =>
          import(
            /* webpackChunkName: "currency-rate-cop-usd" */ '@/views/currency-rate/CurrencyRateUSDCOPList.vue'
          )
      },
    ]
  }
];