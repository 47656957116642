// state

const baseState = {
    filters: {
      date: [],
      sortFilter: {
        options: [
          {
            text: 'ID',
            value: 'unique_id'
          },
          {
            text: 'Date time',
            value: 'date_transaction'
          }
        ],
        optionValue: 'unique_id',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1
      },
      searchFilter: {
        searchValue: null,
        options: [
          {
            text: 'ID',
            value: 'unique_id'
          },
          {
            text: 'Referencia bancaria',
            value: 'referencia'
          },
          {
            text: 'Teléfono',
            value: 'telefono'
          }
        ],
        optionValue: null
      },
      userTypeFilter: {
        options: [
          {
            text: 'Usuario',
            value: '10'
          },
          {
            text: 'Conductor',
            value: '11'
          },
          {
            text: 'Corporativo',
            value: '5'
          },
          {
            text: 'Tienda',
            value: '11_store'
          }
        ],
        optionValue: ''
      },
      transactionStatusFilter: {
        options: [
          {
            text: 'Guardada',
            value: '0'
          },
          {
            text: 'Usada',
            value: '1'
          },
          {
            text: 'Omitida',
            value: '2'
          },
        ],
        optionValue: ''
      },
      pagination: {
        page: 1,
        pages: 1,
        limit: 10
      },
      expandTable: true
    }
  };
  
  const state = baseState;
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = { ...state.filters, ...filters };
    }
  };
  
  const actions = {
    setFilters({ commit }, user) {
      commit('SET_FILTERS', user);
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', baseState);
    }
  };
  
  const getters = {
    user: (state) => state.filters
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
  