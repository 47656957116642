// state

const baseState = {
    filters: {
      pagination: {
        page: 1,
        pages: 1,
        limit: 10
      },
      expandTable: false,
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'Teléfono',
            value: 'phone_number'
          },
          {
            text: 'RIF',
            value: 'rif'
          }
        ],
        optionValue: ''
      },
      bankAutoComplete: {
        value: [],
        options: [],
        selectAll: false
      },
      typeAccountFilter: {
        options: [
          {
            text: 'Cuenta corriente',
            value: 'Corriente'
          }, 
          {
            text: 'Cuenta ahorro',
            value: 'Ahorro'
          }
        ],
        optionValue: '',
      },
    }
  };
  
  const state = baseState;
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = { ...state.filters, ...filters };
    }
  };
  
  const actions = {
    setFilters({ commit }, user) {
      commit('SET_FILTERS', user);
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', baseState);
    }
  };
  
  const getters = {
    filters: (state) => state.filters
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
  